import { FC, PropsWithChildren, RefObject, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { useOutsideClick } from 'hooks/use-outside-click';
import { ModalContainer } from './components';

import './modal.scss';

export type ModalSize = 'small' | 'medium' | 'medium_large' | 'large';

export interface IModalProps extends PropsWithChildren {
  isOpen?: boolean;
  size?: ModalSize;
  // This prop is used to skip CSSTransition for modals that already animated in <GlobalModal/> component
  // see the todo in component return
  isGlobalModal?: boolean;
  onClose?: () => void;
  onCloseAlt?: () => void;
  parentClassName?: string;
  className?: string;
  withBackdropClick?: boolean;
  hideCloseButton?: boolean;
  excludeFromOutsideClickContentRef?: RefObject<any>;
}

export const Modal: FC<IModalProps> = ({
  children,
  isOpen = false,
  withBackdropClick = false,
  excludeFromOutsideClickContentRef,
  isGlobalModal = false,
  ...restProps
}) => {
  const ref = useRef(null);

  useEffect(() => {
    document.body.style.overflow = isOpen ? 'hidden' : 'auto';

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isOpen]);

  useOutsideClick(
    ref,
    isOpen,
    restProps.onCloseAlt ? restProps.onCloseAlt : restProps.onClose,
    withBackdropClick,
    excludeFromOutsideClickContentRef
  );

  // TODO: To remove isGlobalModal after all modals be moved to the GlobalModal approach
  return isGlobalModal ? (
    <ModalContainer {...restProps} contentRef={ref}>
      {children}
    </ModalContainer>
  ) : (
    <CSSTransition in={isOpen} timeout={400} classNames="modal-wrapper" unmountOnExit>
      <ModalContainer {...restProps} contentRef={ref}>
        {children}
      </ModalContainer>
    </CSSTransition>
  );
};
