import './cor-three-column-text-tile.scss';

import React, { FC } from 'react';
import { ICSThreeColumnTileProps } from 'corporate-components/cor-three-column/cor-three-column';
import { get } from 'lodash-es';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { ICSCorButton } from 'corporate-interfaces';
import { CorButtonLink } from 'components/cor-button-link';

export const CorThreeColumnTextTile: FC<ICSThreeColumnTileProps> = ({ icon_text, children }) => {
  const { text: title, button: buttonCompound } = icon_text;
  const asset = (button: ICSCorButton) => corContentstackAssetLinkResolver(button)?.url;

  if (!title && !children && !buttonCompound?.button?.href) {
    return null;
  }

  return (
    <div className={'cor-three-column-text-tile'}>
      <div className="cor-three-column-text-tile__text-content">
        {title && <div className={'cor-three-column-text-tile__title cor-typography__h5-orange'}>{title}</div>}
        {children && <div className={'cor-three-column-text-tile__description'}>{children}</div>}
        {(buttonCompound?.button?.href || buttonCompound?.button?.title) && (
          <div className="cor-three-column-tile__button">
            <CorButtonLink
              color={(buttonCompound?.button_style || 'primary').toLowerCase()}
              href={asset(buttonCompound) ?? buttonCompound?.button?.href}
              target={buttonCompound?.open_in_a_new_tab ? '_blank' : '_self'}
              iconUrl={get(buttonCompound, 'button_icon[0].cdn_url')}
              iconPosition={buttonCompound?.button_alignment}
              asset={asset(buttonCompound)}
            >
              {buttonCompound?.button.title}
            </CorButtonLink>
          </div>
        )}
      </div>
    </div>
  );
};
