import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { selectIsUserCountryCanada } from 'store/auth/selectors';
import { useOutsideClick } from 'hooks/use-outside-click';
import { selectBankAccountsData, selectCreditCardsData } from 'store/payment-methods/selectors';
import { ContentstackText } from 'components/contentstack';
import { CreditCardImageUrl } from 'constants/credit-card-type-imge-url';
import { formatPaymentAccountNumber } from 'utils/format-payment-account-number';
import arrowDownRed from 'assets/images/icons/icon-red-down-arrow.svg';
import { BankAccountType, CountryCodes, PadBankAccountType, PaymentMethods } from 'constants/payment-methods.enum';
import { getInvoicesSummary } from 'store/invoices/actions';
import { useLocalStorage } from 'hooks/use-local-storage';
import { SHOP_LANGUAGES } from 'constants/shop-locales';
import { selectSelectedInvoices } from 'store/invoices/selectors';
import { getPaymentMethods } from 'store/payment-methods/actions';
import { useContent } from 'hooks/use-content';
import { ISelectedPaymentMethod } from 'store/invoices/reducers';
import { useAddOrRemovePaymentMethodSuccesOrErrorModal } from 'hooks/use-add-or-remove-payment-method-success-or-error-modal';

import './payment-methods-dropdown.scss';

export interface IPaymentMethodsDropdownProps {
  contentstackPath: string;
  selectedPaymentMethod: ISelectedPaymentMethod | null;
  onPaymentMethodChange: (paymentMethod: ISelectedPaymentMethod) => void;
  onAddNewPaymentOption?: () => void;
  ignoreInvoiceSummaryRequest?: boolean;
}

export const PaymentMethodsDropdown: FC<IPaymentMethodsDropdownProps> = ({
  contentstackPath,
  selectedPaymentMethod,
  onPaymentMethodChange,
  onAddNewPaymentOption,
  ignoreInvoiceSummaryRequest = false,
}) => {
  const { getContentByKey } = useContent();
  const bankAccounts = useSelector(selectBankAccountsData);
  const creditCards = useSelector(selectCreditCardsData);
  const selectedInvoices = useSelector(selectSelectedInvoices);
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const isUserCountryCanada = useSelector(selectIsUserCountryCanada);
  const [locale] = useLocalStorage('locale', null);
  const countryCode = locale === SHOP_LANGUAGES.ENGLISH_UNITED_STATES ? CountryCodes.US : CountryCodes.CANADA;
  const { addPaymentMethodFailModal } = useAddOrRemovePaymentMethodSuccesOrErrorModal();

  const getPaymentMethodsFailModal = useCallback(
    (status: number, messageId: string) => {
      addPaymentMethodFailModal({ status, messageId });
    },
    [addPaymentMethodFailModal]
  );

  useEffect(() => {
    dispatch(
      getPaymentMethods.request({
        onFailCallBack: getPaymentMethodsFailModal,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSelectChange = (option: ISelectedPaymentMethod) => {
    const isPaymentMethodCreditCard = !isEmpty(option['cardType']);
    const bankAccountType = isUserCountryCanada ? PaymentMethods.PAD_BANK_ACCOUNT : PaymentMethods.ACH_BANK_ACCOUNT;

    onPaymentMethodChange(option);

    if (!ignoreInvoiceSummaryRequest) {
      dispatch(
        getInvoicesSummary.request({
          invoiceBalances: selectedInvoices.map((invoice) => invoice.confirmedBalance),
          countryCode,
          paymentMethod: isPaymentMethodCreditCard ? PaymentMethods.CREDIT_CARD : bankAccountType,
        })
      );
    }
    setIsOpen(false);
  };

  const toggleDropdown = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  useOutsideClick(dropdownRef, isOpen, toggleDropdown, true);

  const selectedPaymentClassName = classNames('payment-summary-dropdown__select-value', {
    'payment-summary-dropdown__select-value--placeholder': !selectedPaymentMethod,
    'payment-summary-dropdown__select-value--selected': selectedPaymentMethod,
  });

  const renderSelectedPayment = (selectedPayment: ISelectedPaymentMethod) => {
    if ('cardType' in selectedPayment) {
      return (
        <>
          <div className="payment-summary-dropdown__card-type">
            <img
              className="payment-summary-dropdown__card-type-img"
              src={CreditCardImageUrl[selectedPayment?.cardType]}
              alt="card type"
            />
            <span className="payment-summary-dropdown__card-type-name">{selectedPayment?.cardType}</span>
          </div>

          <span className="payment-summary-dropdown__card-number">
            {formatPaymentAccountNumber(selectedPayment?.cardLastFourDigits)}
          </span>
        </>
      );
    }

    if ('accountType' in selectedPayment) {
      return (
        <>
          <span className="payment-summary-dropdown__account-type">
            {renderAccountHolderType(selectedPayment?.accountType)}
          </span>
          <span className="payment-summary-dropdown__account-number">
            {formatPaymentAccountNumber(selectedPayment?.publicAccountNumber)}
          </span>
        </>
      );
    }
  };

  const renderAccountHolderType = (type: string): string => {
    const accountHolderTypeByCountry = isUserCountryCanada ? PadBankAccountType.PERSONAL : BankAccountType.PERSONAL;

    return type.includes(accountHolderTypeByCountry)
      ? getContentByKey(`${contentstackPath}.dropdown.personal_account_holder_type`, '')
      : getContentByKey(`${contentstackPath}.dropdown.business_account_holder_type`, '');
  };

  return (
    <div className="payment-summary-dropdown" ref={dropdownRef}>
      <div className="payment-summary-dropdown__select" onClick={toggleDropdown}>
        <div className={selectedPaymentClassName}>
          {selectedPaymentMethod ? (
            renderSelectedPayment(selectedPaymentMethod)
          ) : (
            <ContentstackText contentKey={`${contentstackPath}.dropdown.dropdown_placeholder`} />
          )}
        </div>
        <div className="payment-summary-dropdown__select-arrow">
          <img className="payment-summary-dropdown__select-arrow-icon" src={arrowDownRed} alt="arrow" />
        </div>
      </div>

      {isOpen && (
        <ul className="payment-summary-dropdown__options">
          {!!creditCards?.length && (
            <div className="payment-summary-dropdown__cards">
              <div className="payment-summary-dropdown__label">
                <ContentstackText contentKey={`${contentstackPath}.dropdown.cards_label`} />
              </div>
              {creditCards.map((card, index) => (
                <li className="payment-summary-dropdown__card" key={index} onClick={() => handleSelectChange(card)}>
                  <div className="payment-summary-dropdown__card-type">
                    <img
                      className="payment-summary-dropdown__card-type-img"
                      src={CreditCardImageUrl[card?.cardType]}
                      alt="card type"
                    />
                    <span className="payment-summary-dropdown__card-type-name">{card?.cardType}</span>
                  </div>

                  <span className="payment-summary-dropdown__card-number">
                    {formatPaymentAccountNumber(card?.cardLastFourDigits)}
                  </span>
                </li>
              ))}
            </div>
          )}

          {!!bankAccounts?.length && (
            <div className="payment-summary-dropdown__accounts">
              <div className="payment-summary-dropdown__label">
                <ContentstackText contentKey={`${contentstackPath}.dropdown.banks_label`} />
              </div>
              {bankAccounts.map((account, index) => (
                <li
                  className="payment-summary-dropdown__account"
                  key={index}
                  onClick={() => handleSelectChange(account)}
                >
                  <span className="payment-summary-dropdown__account-type">
                    {renderAccountHolderType(account.accountType)}
                  </span>
                  <span className="payment-summary-dropdown__account-number">
                    {formatPaymentAccountNumber(account?.publicAccountNumber)}
                  </span>
                </li>
              ))}
            </div>
          )}
          {onAddNewPaymentOption && (
            <li className="payment-summary-dropdown__add-option">
              <button
                type="button"
                className="payment-summary-dropdown__add-option-button"
                onClick={onAddNewPaymentOption}
              >
                <ContentstackText contentKey={`${contentstackPath}.dropdown.add_option_label`} />
              </button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
