import { action } from 'typesafe-actions';
import { IUserLocation } from 'types/locations';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export interface IFormWithServerErrors {
  isSubmitting: boolean;
  serverError?: string;
  serverErrorId?: string;
  isDirty?: boolean;
}

export interface IResendVerificationInfo {
  accountId: string;
  isVerificationEmailSent: boolean;
  resendVerificationError: string;
}

export interface IAccountRetrievalInfo {
  userId: string;
  locations: IUserLocation[];
  salesOrg: string;
}

export interface ICustomerProfile {
  companyName: string;
  email: string;
  firstName: string;
  lastName: string;
}

export const submitAccountRetrievalActionConstants = createConstants(types.SUBMIT_ACCOUNT_RETRIEVAL);
export const validationWithCustomerNumberAndZipCodeActionConstants = createConstants(
  types.VALIDATE_ACCOUNT_WITH_CUSTOMER_NUMBER_AND_ZIP_CODE
);
export const submitPersonalInformationActionConstants = createConstants(types.SUBMIT_PERSONAL_INFORMATION);
export const submitAdditionalUserRegistrationActionConstants = createConstants(types.ADDITIONAL_USER_REGISTRATION);
export const verifyEmailByTokenActionConstants = createConstants(types.VERIFY_EMAIL_BY_TOKEN);
export const resendEmailToVerifyAccountActionConstants = createConstants(types.RESEND_EMAIL_TO_VERIFY_ACCOUNT);
export const validateRegistrationTokenActionConstants = createConstants(types.VALIDATE_REGISTRATION_TOKEN);
export const resendVerificationActionConstants = createConstants(types.RESEND_VERIFICATION);

export const submitAccountRetrieval = createRequestActions(submitAccountRetrievalActionConstants);
export const validationWithCustomerNumberAndZipCode = createRequestActions(
  validationWithCustomerNumberAndZipCodeActionConstants
);
export const submitPersonalInformation = createRequestActions(submitPersonalInformationActionConstants);
export const submitAdditionalUserRegistration = createRequestActions(submitAdditionalUserRegistrationActionConstants);
export const verifyEmailByToken = createRequestActions(verifyEmailByTokenActionConstants);
export const resendEmailToVerifyAccount = createRequestActions(resendEmailToVerifyAccountActionConstants);
export const validateRegistrationToken = createRequestActions(validateRegistrationTokenActionConstants);
export const resendVerification = createRequestActions(resendVerificationActionConstants);

export const setActiveRegistrationStep = (activeStep: number) =>
  action(types.SET_ACTIVE_STEP, {
    activeStep,
  });
export const setAccountRetrievalFormDirty = (isDirty: boolean) =>
  action(types.SET_ACCOUNT_RETRIEVAL_FORM_DIRTY, { isDirty });
export const setValidateWithoutInvoiceFormFormDirty = (isDirty: boolean) =>
  action(types.SET_VALIDATE_WITHOUT_INVOICE_FORM_DIRTY, { isDirty });

export const setPersonalInfoFormDirty = (isDirty: boolean) => action(types.SET_PERSONAL_INFO_FORM_DIRTY, { isDirty });

export const resetEmailSendStatus = () => action(types.RESET_VERIFICATION_EMAIL_SEND_STATUS);

export const clearServerErrors = () => action(types.CLEAR_SERVER_ERRORS, {});

export const setInvalidTokenError = (error: string | null) => action(types.SET_INVALID_TOKEN_ERROR, { error });

export const clearCustomerProfile = () => action(types.CLEAR_CUSTOMER_PROFILE);
export const clearResendVerificationError = () => action(types.CLEAR_RESEND_VERIFICATION_ERROR);
