import { combineReducers, AnyAction, Reducer } from 'redux';
import { RouterState, LOCATION_CHANGE } from 'redux-first-history';
import { History } from 'history';
import { rootReducerActionTypes } from 'store/root-reducer-actions';
import authReducer, { IAuthState, authInitialState } from 'store/auth/reducer';
import cartInfoReducer, { IShoppingCartState, shoppingCartInitialState } from 'store/shopping-cart/reducer';
import customerRegistrationReducer, {
  ICustomerRegistrationState,
  customerRegistrationInitialState,
} from './customer-registration/reducer';
import customerLoginReducer, { ICustomerLoginState, customerLoginInitialState } from './customer-login/reducer';
import productsReducer, { productsInitialState, IProductsState } from './products/reducer';
import searchProductsReducer, { searchProductsInitialState, ISearchProductsState } from './search-products/reducer';
import resetPasswordReducer, { resetPasswordInitialState, IResetPasswordState } from './reset-password/reducer';
import accountReducer, { IAccountState, accountInitialState } from './account/reducer';
import categoriesNavigationReducer, {
  ICategoriesNavigationState,
  categoriesNavigationInitialState,
} from './categories-navigation/reducer';
import checkoutInfoReducer, { checkoutInfoInitialState, ICheckoutState } from './checkout/reducer';
import homeReducer, { homeInitialState, IHomeState } from './home/reducer';
import dashboardReducer, { dashboardInitialState, IDashboardState } from './dashboard/reducer';
import optOutFormReducer, { IOptOutFormState, optOutFormInitialState } from './opt-out/reducer';
import quickShopReducer, { IQuickShopState, quickShopInitialState } from './quick-shop/reducer';
import quotesReducer, { IQuotesState, quotesInitialState } from './quotes/reducer';
import productDetailsReducer, { IProductDetailsState, productDetailsInitialState } from './product-details/reducer';
import shoppingListsReducer, { IShoppingListsState, shoppingListsInitialState } from './shopping-lists/reducer';
import orderCutOffReducer, { IOrderCutOffState, orderCutOffInitialState } from './order-cut-off/reducer';
import itemsReplacementsReducer, {
  IItemsReplacementsState,
  itemsReplacementsInitialState,
} from './items-replacements/reducer';
import notificationsReducer, {
  ICustomerNotificationsState,
  customerNotificationsInitialState,
} from './notifications/reducer';
import similarItemsReducer, { ISimilarItemsState, similarItemsInitialState } from './similar-items/reducer';
import youMayAlsoNeedItemsReducer, {
  IYouMayAlsoNeedItemsState,
  youMayAlsoNeedItemsInitialState,
} from './you-may-also-need-items/reducer';
import moreProductsFromBrandReducer, {
  IMoreProductsFromBrandState,
  moreProductsFromBrandInitialState,
} from './more-products-from-brand/reducer';
import guestReducer, { guestInitialState, IGuestState } from './guest/reducer';
import captchaReducer, { captchaInitialState, ICaptchaState } from './captcha/reducer';
import uiReducer, { IUIState, uiStateInitialState } from './ui/reducer';
import corTemporaryFilterFacets, {
  corTemporaryFilterFacetsInitialState,
  ICorTemporaryFilterFacets,
} from './cor-temporary-facets/reducer';
import geolocationRedirect, {
  IGeolocationRedirect,
  geolocationRedirectInitialState,
} from './geolocation-modal/reducer';
import {
  paymentFieldTokenReducer,
  IPaymentFieldTokenState,
  paymentFieldTokenInitialState,
} from './payment-field-token/reducer';
import paymentMethodsReducer, { IPaymentMethodsState, paymentMethodsInitialState } from './payment-methods/reducers';
import { IInvoicesState, invoicesInitialState, invoicesReducer } from './invoices/reducers';
import { ISchedulePaymentState, schedulePaymentInitialState, schedulePaymentReducer } from './schedule-payment/reducer';
import {
  IPaymentTransactionsState,
  paymentTransactionsInitialState,
  paymentTransationsReducer,
} from './payment-transactions/reducer';
import paymentHistoryReducer, { IPaymentHistory, paymentHistoryInitialState } from './payment-history/reducer';
import paymentDetailsReducer, {
  IPaymentTransactionDetailsState,
  paymentTransactionDetailsInitialState,
} from './payment-details/reducer';
import {
  autopayOptOut,
  autopayOptOutInitialState,
  IAutopayOptOutState,
  autopayOptIn,
  autopayOptInInitialState,
  IAutopayOptInState,
} from './autopay/reducers';

export interface IAppState {
  auth: IAuthState;
  guest: IGuestState;
  customerRegistration: ICustomerRegistrationState;
  customerLogin: ICustomerLoginState;
  products: IProductsState;
  searchProducts: ISearchProductsState;
  account: IAccountState;
  resetPassword: IResetPasswordState;
  cartInfo: IShoppingCartState;
  categoriesNavigation: ICategoriesNavigationState;
  checkoutInfo: ICheckoutState;
  home: IHomeState;
  dashboard: IDashboardState;
  optOutForm: IOptOutFormState;
  quickShop: IQuickShopState;
  productDetails: IProductDetailsState;
  invoices: IInvoicesState;
  paymentFieldToken: IPaymentFieldTokenState;
  paymentMethods: IPaymentMethodsState;
  paymentHistory: IPaymentHistory;
  paymentDetails: IPaymentTransactionDetailsState;
  schedulePayment: ISchedulePaymentState;
  paymentTransations: IPaymentTransactionsState;
  shoppingLists: IShoppingListsState;
  quotes: IQuotesState;
  itemsReplacements: IItemsReplacementsState;
  orderCutOff: IOrderCutOffState;
  customerNotifications: ICustomerNotificationsState;
  similarItems: ISimilarItemsState;
  youMayAlsoNeedItems: IYouMayAlsoNeedItemsState;
  moreProductsFromBrand: IMoreProductsFromBrandState;
  captcha: ICaptchaState;
  corTemporaryFilterFacets: ICorTemporaryFilterFacets;
  geolocationRedirect: IGeolocationRedirect;
  ui: IUIState;
  autopayOptOut: IAutopayOptOutState;
  autopayOptIn: IAutopayOptInState;
}

export interface IState {
  app: IAppState;
  router: RouterState;
}

// Move initial state from every created reducer to this state
// We can use it to test our selectors
export const appInitialState: IAppState = {
  auth: authInitialState,
  guest: guestInitialState,
  customerRegistration: customerRegistrationInitialState,
  customerLogin: customerLoginInitialState,
  products: productsInitialState,
  searchProducts: searchProductsInitialState,
  account: accountInitialState,
  resetPassword: resetPasswordInitialState,
  categoriesNavigation: categoriesNavigationInitialState,
  cartInfo: shoppingCartInitialState,
  checkoutInfo: checkoutInfoInitialState,
  home: homeInitialState,
  dashboard: dashboardInitialState,
  optOutForm: optOutFormInitialState,
  productDetails: productDetailsInitialState,
  invoices: invoicesInitialState,
  paymentFieldToken: paymentFieldTokenInitialState,
  paymentMethods: paymentMethodsInitialState,
  paymentHistory: paymentHistoryInitialState,
  paymentTransations: paymentTransactionsInitialState,
  paymentDetails: paymentTransactionDetailsInitialState,
  quickShop: quickShopInitialState,
  quotes: quotesInitialState,
  schedulePayment: schedulePaymentInitialState,
  shoppingLists: shoppingListsInitialState,
  itemsReplacements: itemsReplacementsInitialState,
  orderCutOff: orderCutOffInitialState,
  customerNotifications: customerNotificationsInitialState,
  similarItems: similarItemsInitialState,
  youMayAlsoNeedItems: youMayAlsoNeedItemsInitialState,
  moreProductsFromBrand: moreProductsFromBrandInitialState,
  captcha: captchaInitialState,
  corTemporaryFilterFacets: corTemporaryFilterFacetsInitialState,
  geolocationRedirect: geolocationRedirectInitialState,
  ui: uiStateInitialState,
  autopayOptOut: autopayOptOutInitialState,
  autopayOptIn: autopayOptInInitialState,
};

export const appReducer = combineReducers<IAppState>({
  auth: authReducer,
  guest: guestReducer,
  customerRegistration: customerRegistrationReducer,
  customerLogin: customerLoginReducer,
  products: productsReducer,
  searchProducts: searchProductsReducer,
  account: accountReducer,
  resetPassword: resetPasswordReducer,
  cartInfo: cartInfoReducer,
  categoriesNavigation: categoriesNavigationReducer,
  checkoutInfo: checkoutInfoReducer,
  home: homeReducer,
  dashboard: dashboardReducer,
  optOutForm: optOutFormReducer,
  productDetails: productDetailsReducer,
  invoices: invoicesReducer,
  paymentFieldToken: paymentFieldTokenReducer,
  paymentMethods: paymentMethodsReducer,
  paymentHistory: paymentHistoryReducer,
  paymentTransations: paymentTransationsReducer,
  paymentDetails: paymentDetailsReducer,
  quickShop: quickShopReducer,
  quotes: quotesReducer,
  schedulePayment: schedulePaymentReducer,
  shoppingLists: shoppingListsReducer,
  itemsReplacements: itemsReplacementsReducer,
  orderCutOff: orderCutOffReducer,
  customerNotifications: notificationsReducer,
  similarItems: similarItemsReducer,
  youMayAlsoNeedItems: youMayAlsoNeedItemsReducer,
  moreProductsFromBrand: moreProductsFromBrandReducer,
  captcha: captchaReducer,
  corTemporaryFilterFacets: corTemporaryFilterFacets,
  geolocationRedirect: geolocationRedirect,
  ui: uiReducer,
  autopayOptOut: autopayOptOut,
  autopayOptIn: autopayOptIn,
});

const createAppReducer = (routerReducer: Reducer<RouterState>) => (state: IState, action: AnyAction): IState =>
  combineReducers({
    router: routerReducer,
    app: appReducer,
  })(state, action);

const createRootReducer = (history: History, routerReducer) => (state: IState | any, action: AnyAction): IState => {
  if (action.type === rootReducerActionTypes.APP_RESET) {
    return {
      router: state.router,
      app: appInitialState,
    };
  }

  if (action.type === LOCATION_CHANGE) {
    const prevPathname = state.router.location?.pathname;
    const currentPathname = history.location.pathname;

    if (prevPathname !== currentPathname) {
      return {
        ...createAppReducer(routerReducer)(state, action),
        app: {
          ...state.app,
          auth: {
            ...authInitialState,
          },
        },
      };
    }
  }

  return createAppReducer(routerReducer)(state, action);
};

export default createRootReducer;
