import { action } from 'typesafe-actions';
import { createConstants } from 'store/actions-constants-creator';
import { createRequestActions } from 'store/action-creator';
import types from './action-types';

export const getPaymentMethodsActionConstants = createConstants(types.GET_PAYMENT_METHODS);
export const addCreditCardActionConstants = createConstants(types.ADD_CREDIT_CARD);
export const deleteCreditCardActionConstants = createConstants(types.DELETE_CREDIT_CARD);
export const getCreditCardsActionConstants = createConstants(types.GET_CREDIT_CARDS);
export const addNewBankAccountActionConstants = createConstants(types.ADD_BANK_ACCOUNT);
export const addNewBankAccountEftPadActionConstants = createConstants(types.ADD_BANK_ACCOUNT_EFT_PAD);
export const removeBankAccountActionConstants = createConstants(types.REMOVE_BANK_ACCOUNT);
export const removeBankAccountEftPadActionConstants = createConstants(types.REMOVE_BANK_ACCOUNT_EFT_PAD);

export const getPaymentMethods = createRequestActions(getPaymentMethodsActionConstants);
export const addCreditCard = createRequestActions(addCreditCardActionConstants);
// export const getCreditCardsActionRequestActions = createRequestActions(getCreditCardsActionConstants);
export const deleteCreditCard = createRequestActions(deleteCreditCardActionConstants);
export const addNewBankAccount = createRequestActions(addNewBankAccountActionConstants);
export const addNewBankAccountEftPad = createRequestActions(addNewBankAccountEftPadActionConstants);
export const removeBankAccount = createRequestActions(removeBankAccountActionConstants);
export const removeBankAccountEftPad = createRequestActions(removeBankAccountEftPadActionConstants);

export const clearPaymentMethods = () => action(types.CLEAR_PAYMENT_METHODS, {});
export const clearAddCreditCardFormErrors = () => action(types.CLEAR_ADD_CREDIT_CARD_FROM_ERRORS, {});
export const clearAddBankAccountFormErrors = () => action(types.CLEAR_ADD_BANK_ACCOUNT_FROM_ERRORS, {});
export const clearAddBankAccountFormEftPadErrors = () => action(types.CLEAR_ADD_BANK_ACCOUNT_EFT_PAD_FORM_ERRORS, {});
export const clearAutopayError = () => action(types.CLEAR_AUTOPAY_ERROR, {});
