import { createSelector } from 'reselect';
import { ICatalogProduct } from 'types/catalog';
import { ICarouselProduct } from 'types/product';
import { IState } from 'store/root-reducer';
import { IFacet, ISelectedFacet, IFilter } from 'types/facets';
import { decorateFacets } from 'utils/facet-utils';
import { IMeta, IProductsState } from './reducer';

export const productsEntitySelector = (state: IState): IProductsState => state.app.products;

export const selectProducts = createSelector(productsEntitySelector, ({ items }): ICatalogProduct[] => items);
export const selectProductsMeta = createSelector(productsEntitySelector, ({ meta }): IMeta => meta);
export const selectProductsFacets = createSelector(productsEntitySelector, ({ facets }): IFacet[] =>
  decorateFacets(facets)
);
export const selectAllDawnBrandsFacets = createSelector(
  selectProductsFacets,
  (facets): string[] => facets.find((item) => item.key === 'brand')?.values || []
);
export const selectProductsDawnBrands = createSelector(productsEntitySelector, ({ dawnBrands }): IFilter[] =>
  dawnBrands.map((item) => {
    return { key: 'brand', value: item };
  })
);
export const selectProductsSelectedFacets = createSelector(
  productsEntitySelector,
  ({ selectedFacets }): ISelectedFacet[] => selectedFacets
);
export const selectProductsCategories = createSelector(
  productsEntitySelector,
  ({ fullCategoriesTree }) => fullCategoriesTree?.categories
);
export const selectPaginationCurrent = createSelector(selectProductsMeta, (meta) => meta.offset / meta.limit);
export const selectPaginationTotal = createSelector(selectProductsMeta, (meta) => Math.ceil(meta.total / meta.limit));
export const selectCurrentProductsRange = createSelector(selectProductsMeta, (meta) => {
  return [meta.offset + 1, Math.min(meta.total, meta.offset + meta.limit)];
});
export const selectProductsTotal = createSelector(selectProductsMeta, (meta) => meta.total);
export const selectQueryAfterRemoval = createSelector(selectProductsMeta, (meta) => meta.queryAfterRemoval);
export const selectSkusTotal = createSelector(selectProductsMeta, (meta) => meta.totalSkus);
export const selectProductsLoading = createSelector(selectProductsMeta, (meta) => meta.isLoading);
export const selectTaxonomyPath = createSelector(productsEntitySelector, ({ taxonomyPath }) => taxonomyPath);
export const selectCategoryName = createSelector(productsEntitySelector, ({ selectedCategory }) =>
  selectedCategory?.name.trim()
);

export const selectCategoryKey = createSelector(
  productsEntitySelector,
  ({ selectedCategory }) => selectedCategory?.key
);

export const selectTemporarySelectedCategoryKey = createSelector(
  productsEntitySelector,
  ({ temporarySelectedCategory }) => temporarySelectedCategory?.key
);

export const selectTemporaryProductFacets = createSelector(productsEntitySelector, ({ temporaryFacets }) =>
  decorateFacets(temporaryFacets)
);

export const selectTemporaryProductsSelectedFacets = createSelector(
  productsEntitySelector,
  ({ temporarySelectedFacets }): ISelectedFacet[] => temporarySelectedFacets
);

export const selectCarouselProducts = createSelector(
  productsEntitySelector,
  ({ carouselProducts }): ICarouselProduct[] => carouselProducts
);

export const selectMustHaveProducts = createSelector(
  productsEntitySelector,
  ({ mustHaveProducts }): ICarouselProduct[] => mustHaveProducts
);

export const selectExpandedFacet = createSelector(selectProductsMeta, (meta) => meta.expandedFacet);
