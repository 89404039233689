import { Actions } from 'constants/actions.enum';
import { IBankAccounts, ICreditCards } from 'store/payment-methods/reducers';
import { getPaymentMethodsActionConstants } from 'store/payment-methods/actions';
import { createReducer } from '../reducer-creator';
import * as actions from './actions';

export interface IAutopayOptOutState {
  isLoading: boolean;
}

export interface IAutopayOptInState {
  isLoading: boolean;
  selectedAutopayPaymentMethod: ICreditCards | IBankAccounts | null;
}

export const autopayOptOutInitialState: IAutopayOptOutState = {
  isLoading: false,
};

export const autopayOptInInitialState: IAutopayOptInState = {
  isLoading: false,
  selectedAutopayPaymentMethod: null,
};

export const autopayOptOut = createReducer<IAutopayOptOutState>(
  {
    [actions.autopayOptOutFormActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.autopayOptOutFormActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [actions.autopayOptOutFormActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      isLoading: false,
    }),
  },
  autopayOptOutInitialState
);

export const autopayOptIn = createReducer<IAutopayOptInState>(
  {
    [actions.autopayOptInActionConstants[Actions.REQUEST]]: (state) => ({
      ...state,
      isLoading: true,
    }),
    [actions.autopayOptInActionConstants[Actions.SUCCESS]]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [actions.autopayOptInActionConstants[Actions.FAIL]]: (state) => ({
      ...state,
      isLoading: false,
    }),
    [getPaymentMethodsActionConstants[Actions.SUCCESS]]: (state, { payload: { bankAccounts, creditCards } }) => ({
      ...state,
      selectedAutopayPaymentMethod:
        creditCards.find(({ selectedForAutopay }) => selectedForAutopay) ||
        bankAccounts.find(({ selectedForAutopay }) => selectedForAutopay) ||
        null,
    }),
  },
  autopayOptInInitialState
);
