import { CommonBluesnapErrorIds } from 'constants/common-bluesnap-error-ids.enum';
import { usePaymentMethodsErrorModal, useSuccessErrorMessageModal } from './use-global-modal';

export const useAddOrRemovePaymentMethodSuccesOrErrorModal = () => {
  const showAddOrRemovePaymentMethodSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    hasCrossButton: true,
  });

  const addPaymentMethodSuccessModal = () => {
    showAddOrRemovePaymentMethodSuccessModal({ messageId: 'MSG187' });
  };

  const removePaymentMethodSuccessModal = () => {
    showAddOrRemovePaymentMethodSuccessModal({ messageId: 'MSG188' });
  };

  const showAddOrRemovePaymentMethodFailModal = usePaymentMethodsErrorModal({
    messageId: 'MSG189',
  });

  const removePaymentMethodFailModal = (errorId: string, errorMessageId: string) => {
    if (errorMessageId === 'MSG211') return showAddOrRemovePaymentMethodFailModal({ messageId: 'MSG211' });

    return showAddOrRemovePaymentMethodFailModal({
      messageId: errorId === CommonBluesnapErrorIds.INVALID_PAYMENT_INPUT ? errorMessageId : 'MSG189',
    });
  };

  const addPaymentMethodFailModal = ({ messageId = '', status = 0 }: { messageId?: string; status?: number }) => {
    if (messageId === 'MSG211' || [503, 429].includes(status))
      return showAddOrRemovePaymentMethodFailModal({ messageId: 'MSG211' });
    else if (messageId !== 'MSG190' && messageId !== 'MSG202') return showAddOrRemovePaymentMethodFailModal();
  };

  return {
    removePaymentMethodSuccessModal,
    removePaymentMethodFailModal,
    addPaymentMethodFailModal,
    addPaymentMethodSuccessModal,
  };
};
