import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { ModalFooter, ModalHeader } from 'components/modals/common/modal/components';
import { CorButton } from 'components/cor-button';
import { ContentstackText } from 'components/contentstack';
import { autopayOptOut } from 'store/autopay/actions';
import { selectAutopayOptOutLoading } from 'store/autopay/selectors';
import { LoadingIndicator } from 'components/loading-indicator';
import { getPaymentMethods } from 'store/payment-methods/actions';
import { useSuccessErrorMessageModal } from 'hooks/use-global-modal';

import './autopay-opt-out-modal.scss';

export const AutopayOptOutModal: FC<IModalProps> = ({ isOpen, onClose }) => {
  const contentstackPath = 'modals.0.autopay_opt_out_modal.0';
  const dispatch = useDispatch();
  const isLoading = useSelector(selectAutopayOptOutLoading);
  const showAutopayOptOutSuccessModal = useSuccessErrorMessageModal({
    type: 'success',
    messageId: 'MSG208',
  });

  const autopayOptOutSuccessHandler = () => {
    dispatch(getPaymentMethods.request());
    showAutopayOptOutSuccessModal();
  };
  const autopayOptOutFailHandler = () => {
    onClose && onClose();
  };

  const onProceedClick = () => {
    dispatch(
      autopayOptOut.request({
        onSuccessCallBack: autopayOptOutSuccessHandler,
        onFailCallBack: autopayOptOutFailHandler,
      })
    );
  };

  return (
    <Modal withBackdropClick isOpen={isOpen} onClose={onClose} className="autopay-opt-out-modal" size="medium">
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <ModalHeader titleContentstackPath={`${contentstackPath}.header`} />

          <div className="autopay-opt-out-modal__wrapper">
            <ContentstackText contentKey={`${contentstackPath}.description`} />
          </div>
          <ModalFooter
            className="autopay-opt-out-modal__footer"
            hasCancelButton
            contentstackPath={contentstackPath}
            cancelButtonHandler={onClose}
          >
            <CorButton color="primary" className="cancel-scheduled-payment-modal__save_button" onClick={onProceedClick}>
              <ContentstackText contentKey={`${contentstackPath}.save_button_label`} />
            </CorButton>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};
