import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { autopayOptIn, autopayOptInActionConstants, autopayOptOutFormActionConstants } from 'store/autopay/actions';
import { IAutopayOptOutPayload } from './autopay-opt-out';

export interface IAutopayOptInPayload {
  onSuccessCallBack?: () => void;
  onFailCallBack?: () => void;
}

export function* autopayOptInOnSuccessSagaHandler({ payload }: IAction<IAutopayOptInPayload>) {
  yield take(autopayOptInActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* autopayOptInFailSagaHandler({ payload }: IAction<IAutopayOptOutPayload>) {
  yield take(autopayOptOutFormActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack();
  }
}

export function* autopayOptInSagaHandler({
  payload: { onSuccessCallBack, onFailCallBack, ...data },
}: IAction<IAutopayOptInPayload>) {
  yield put(doRequest(autopayOptIn, Endpoints.AUTOPAY, 'post', { ...data }));
}

export default function* autopayOptInSaga() {
  yield takeLatest(autopayOptInActionConstants[Actions.REQUEST], autopayOptInOnSuccessSagaHandler);
  yield takeLatest(autopayOptInActionConstants[Actions.REQUEST], autopayOptInFailSagaHandler);
  yield takeLatest(autopayOptInActionConstants[Actions.REQUEST], autopayOptInSagaHandler);
}
