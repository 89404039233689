import { createSelector } from 'reselect';
import { IState } from 'store/root-reducer';
import { IAutopayOptInState, IAutopayOptOutState } from './reducers';

export const selectAutopayOptOutEntity = (state: IState): IAutopayOptOutState => state.app.autopayOptOut;
export const selectAutopayOptInEntity = (state: IState): IAutopayOptInState => state.app.autopayOptIn;

export const selectAutopayOptOutLoading = createSelector(selectAutopayOptOutEntity, ({ isLoading }) => isLoading);

export const selectAutopayOptInLoading = createSelector(selectAutopayOptInEntity, ({ isLoading }) => isLoading);

export const selectSelectedAutopayPaymentMethod = createSelector(
  selectAutopayOptInEntity,
  ({ selectedAutopayPaymentMethod }) => selectedAutopayPaymentMethod
);
