import './cor-three-column-tile.scss';

import React, { FC } from 'react';
import classNames from 'classnames';
import { ICSThreeColumnTileProps } from 'corporate-components/cor-three-column/cor-three-column';
import { get } from 'lodash-es';
import { CorTypography } from 'corporate-ui';
import { corContentstackImageLinkResolver } from 'corporate-utils/cor-contentstack-image-link-resolver';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { ICSCorButton } from 'corporate-interfaces';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { CorButtonLink } from 'components/cor-button-link';

export const CorThreeColumnTile: FC<ICSThreeColumnTileProps> = ({ icon_text, children, direction, row }) => {
  const { icon, text: title, button: buttonCompound } = icon_text;
  const iconImage = icon ? corContentstackImageLinkResolver(icon) : undefined;
  const { isDesktop } = useBreakpoint();
  const asset = (buttonCompound: ICSCorButton) => corContentstackAssetLinkResolver(buttonCompound)?.url;

  return (
    <div
      className={classNames('cor-three-column-tile', `cor-three-column-tile--${direction}`)}
      style={isDesktop ? { gridRow: row } : undefined}
    >
      <div className="cor-three-column-tile__icon">
        {iconImage && (
          <img className={'cor-three-column-tile__icon'} src={iconImage.url} alt={iconImage.image_alt_text} />
        )}
      </div>
      {(title || children || buttonCompound?.button?.href) && (
        <div className="cor-three-column-tile__text">
          {title && (
            <CorTypography
              variant={'small-caps'}
              component={'div'}
              className={'cor-three-column-tile__title'}
              color={'dark-gray'}
            >
              {title}
            </CorTypography>
          )}

          {children && <div className={'cor-three-column-tile__description cor-rich-text'}>{children}</div>}

          {(buttonCompound?.button?.href || buttonCompound?.button?.title) && (
            <div className="cor-three-column-tile__button">
              <CorButtonLink
                color={(buttonCompound?.button_style || 'primary').toLowerCase()}
                href={asset(buttonCompound) ?? buttonCompound?.button?.href}
                target={buttonCompound?.open_in_a_new_tab ? '_blank' : '_self'}
                iconUrl={get(buttonCompound, 'button_icon[0].cdn_url')}
                iconPosition={buttonCompound?.button_alignment}
                asset={asset(buttonCompound)}
              >
                {buttonCompound?.button.title}
              </CorButtonLink>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
