import { FC } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import {
  selectIsOrderCutoffNotificationVisible,
  selectIsOrderCutoffNotificationExpired,
  selectOrderCutOffInfo,
  selectHasNextOrderDueDate,
  selectNextOrderDueDate,
  selectNextOrderTimeZone,
} from 'store/order-cut-off/selectors';
import { ContentstackHtml } from 'components/contentstack';
import { getFormattedDeliveryDate } from 'utils/date-format';
import iconCalendarGray from 'assets/images/icons/icon-order-calendar-gray.svg';
import iconCalendarYellow from 'assets/images/icons/icon-order-calendar-yellow.svg';

import './next-order-message.scss';

export interface INextOrderMessageProps {
  fullSize?: boolean;
}

export const NextOrderMessage: FC<INextOrderMessageProps> = ({ fullSize }) => {
  const contentStackPath = 'page_content.order_cutoff_notification[0]';
  const dueDate = useSelector(selectNextOrderDueDate);
  const hasNextOrderDueDate = useSelector(selectHasNextOrderDueDate);
  const timeZone = useSelector(selectNextOrderTimeZone);
  const isCutoffNotificationVisible = useSelector(selectIsOrderCutoffNotificationVisible);
  const isCutoffNotificationExpired = useSelector(selectIsOrderCutoffNotificationExpired);
  const { deliveryDates } = useSelector(selectOrderCutOffInfo);

  const nextDeliveryDate = deliveryDates[1] || deliveryDates[0];

  return (
    <div className={classNames('next-order-message', { 'next-order-message--full-size': fullSize })}>
      <div className="next-order-message__title-wrapper">
        <div className="next-order-message__icon">
          <img src={isCutoffNotificationVisible ? iconCalendarGray : iconCalendarYellow} alt="calendar-icon" />
        </div>
        <div className="next-order-message__title">
          {isCutoffNotificationVisible && isCutoffNotificationExpired && nextDeliveryDate ? (
            <ContentstackHtml
              contentKey={`${contentStackPath}.expired_homepage_message`}
              interpolateParams={{ nextDeliveryDate: getFormattedDeliveryDate(nextDeliveryDate, true) }}
            />
          ) : (
            hasNextOrderDueDate && (
              <>
                <ContentstackHtml
                  contentKey="page_content.order_updates.0.next_order_date_message_new"
                  interpolateParams={{ timeZone: timeZone }}
                  component="span"
                />
                <span className="next-order-message__due-date"> {dueDate}</span>
              </>
            )
          )}
        </div>
      </div>
    </div>
  );
};
