import { FC, useState } from 'react';
import classNames from 'classnames';
import { ContentstackLink, ContentstackText } from 'components/contentstack';
import { useBreakpoint } from 'hooks/use-breakpoint';
import arrowIcon from 'assets/images/icons/icon-chevron-black.svg';
import { useLocalStorage } from 'hooks/use-local-storage';
import { isInternationalSite } from 'utils/get-locale-params';
import { CorContentStackLanguageURLMap, CorContentStackLanguagesURL } from 'constants/cor-locale.enum';

import './credit-card-surcharge-text.scss';

export interface ICreditCardSurchargeTextProps {
  contentstackPath: string;
  fromPaymentSummaryPage?: boolean;
}

export const CreditCardSurchargeText: FC<ICreditCardSurchargeTextProps> = ({
  contentstackPath,
  fromPaymentSummaryPage,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const { isMobile } = useBreakpoint();
  const [locale] = useLocalStorage('locale', null);
  const localeUrl = CorContentStackLanguageURLMap.get(locale);
  const isCanadaFrenchUser = isInternationalSite(locale) && localeUrl === CorContentStackLanguagesURL.FRENCH_CANADA;

  const showToggleIcon = !fromPaymentSummaryPage || isMobile;

  const onIconClick = () => {
    setIsExpanded((prevExpanded) => !prevExpanded);
  };

  const surchargeContainerClassName = classNames('credit-card-surcharge-text__text-container', {
    'credit-card-surcharge-text__text-container--is-canada-french': isCanadaFrenchUser,
    'credit-card-surcharge-text__text-container--is-expanded': isExpanded,
    'credit-card-surcharge-text__text-container--is-collapsed': !isExpanded,
  });

  const iconClassName = classNames('credit-card-surcharge-text__arrow', {
    'credit-card-surcharge-text__arrow--is-expanded': isExpanded,
  });

  return (
    <div className="credit-card-surcharge-text">
      <div className={surchargeContainerClassName}>
        <ContentstackText contentKey={`${contentstackPath}.credit_card_surcharge_legal_text`} />{' '}
        <ContentstackLink contentKey={`${contentstackPath}.terms_and_conditions`} />
      </div>
      {showToggleIcon && (
        <div className="credit-card-surcharge-text__arrow-container">
          <img
            className={iconClassName}
            src={arrowIcon}
            alt={isExpanded ? 'up arrow' : 'down arrow'}
            onClick={onIconClick}
          />
        </div>
      )}
    </div>
  );
};
