import { takeLatest, put, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { Actions } from 'constants/actions.enum';
import { doRequest } from 'store/api/actions';
import { IAction } from 'types/actions';
import { autopayOptOut, autopayOptOutFormActionConstants } from '../actions';

export interface IAutopayOptOutPayload {
  onSuccessCallBack?: () => void;
  onFailCallBack?: () => void;
}

export function* autopayoOptOutOnSuccessSagaHandler({ payload }: IAction<IAutopayOptOutPayload>) {
  yield take(autopayOptOutFormActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* autopayoOptOutOnFailSagaHandler({ payload }: IAction<IAutopayOptOutPayload>) {
  yield take(autopayOptOutFormActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack();
  }
}

export function* autopayoOptOutSagaHandler() {
  yield put(doRequest(autopayOptOut, Endpoints.AUTOPAY, 'delete'));
}

export default function* autopayOptOutSaga() {
  yield takeLatest(autopayOptOutFormActionConstants[Actions.REQUEST], autopayoOptOutSagaHandler);
  yield takeLatest(autopayOptOutFormActionConstants[Actions.REQUEST], autopayoOptOutOnSuccessSagaHandler);
  yield takeLatest(autopayOptOutFormActionConstants[Actions.REQUEST], autopayoOptOutOnFailSagaHandler);
}
