enum customerRegistrationActionTypes {
  SUBMIT_ACCOUNT_RETRIEVAL = 'SUBMIT_ACCOUNT_RETRIEVAL',

  VALIDATE_ACCOUNT_WITH_CUSTOMER_NUMBER_AND_ZIP_CODE = 'VALIDATE_ACCOUNT_WITH_CUSTOMER_NUMBER_AND_ZIP_CODE',

  SUBMIT_PERSONAL_INFORMATION = 'SUBMIT_PERSONAL_INFORMATION',

  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',

  VERIFY_EMAIL_BY_TOKEN = 'VERIFY_EMAIL_BY_TOKEN',

  RESEND_EMAIL_TO_VERIFY_ACCOUNT = 'RESEND_EMAIL_TO_VERIFY_ACCOUNT',

  RESEND_VERIFICATION = 'RESEND_VERIFICATION',
  CLEAR_RESEND_VERIFICATION_ERROR = 'CLEAR_RESEND_VERIFICATION_ERROR',

  RESET_VERIFICATION_EMAIL_SEND_STATUS = 'RESET_VERIFICATION_EMAIL_SEND_STATUS',

  CLEAR_SERVER_ERRORS = 'CLEAR_SERVER_ERRORS',

  SET_ACCOUNT_RETRIEVAL_FORM_DIRTY = 'SET_ACCOUNT_RETRIEVAL_FORM_DIRTY',

  SET_VALIDATE_WITHOUT_INVOICE_FORM_DIRTY = 'SET_VALIDATE_WITHOUT_INVOICE_FORM_DIRTY',

  SET_PERSONAL_INFO_FORM_DIRTY = 'SET_PERSONAL_INFO_FORM_DIRTY',

  ADDITIONAL_USER_REGISTRATION = 'ADDITIONAL_USER_REGISTRATION',

  VALIDATE_REGISTRATION_TOKEN = 'VALIDATE_REGISTRATION_TOKEN',

  SET_INVALID_TOKEN_ERROR = 'SET_INVALID_TOKEN_ERROR',

  CLEAR_CUSTOMER_PROFILE = 'CLEAR_CUSTOMER_PROFILE',
}

export default customerRegistrationActionTypes;
