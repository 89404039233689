import { all, spawn } from 'redux-saga/effects';
import apiSaga from './api/sagas';
import customerRegistrationSagas from './customer-registration/sagas';
import customerLoginSagas from './customer-login/sagas';
import productsSagas from './products/sagas';
import searchProductsSagas from './search-products/sagas';
import resetPasswordSagas from './reset-password/sagas';
import accountSagas from './account/sagas';
import cartInfoSagas from './shopping-cart/sagas';
import checkoutSagas from './checkout/sagas';
import categoriesNavigationSaga from './categories-navigation/sagas';
import homeSagas from './home/sagas';
import msrSagas from './msr/sagas';
import dashboardSagas from './dashboard/sagas';
import authSagas from './auth/sagas';
import optOutFormSagas from './opt-out/sagas';
import quickShopSaga from './quick-shop/sagas/get-sku-total-amount';
import productDetailsSagas from './product-details/sagas';
import shoppingListsSagas from './shopping-lists/sagas';
import quotesSagas from './quotes/sagas';
import itemsReplacementsSagas from './items-replacements/sagas';
import orderCutOffSagas from './order-cut-off/sagas';
import customerNotificationSagas from './notifications/sagas';
import similarItemsSagas from './similar-items/sagas';
import youMayAlsoNeedItemsSaga from './you-may-also-need-items/sagas';
import moreProductsFromBrand from './more-products-from-brand/sagas';
import guestSagas from './guest/sagas';
import captchaSagas from './captcha/sagas';
import paymentMethodsSagas from './payment-methods/saga';
import paymentFieldTokenSagas from './payment-field-token/sagas';
import invoicesSaga from './invoices/sagas';
import schedulePaymentSagas from './schedule-payment/sagas';
import paymentTransactionsSaga from './payment-transactions/sagas';
import paymentHistorySaga from './payment-history/sagas';
import paymentDetailsSaga from './payment-details/sagas';
import autopaySagas from './autopay/sagas';

export default function* rootSaga() {
  yield all([
    spawn(apiSaga),
    spawn(customerRegistrationSagas),
    spawn(customerLoginSagas),
    spawn(productsSagas),
    spawn(searchProductsSagas),
    spawn(resetPasswordSagas),
    spawn(accountSagas),
    spawn(cartInfoSagas),
    spawn(checkoutSagas),
    spawn(categoriesNavigationSaga),
    spawn(homeSagas),
    spawn(msrSagas),
    spawn(dashboardSagas),
    spawn(authSagas),
    spawn(optOutFormSagas),
    spawn(productDetailsSagas),
    spawn(invoicesSaga),
    spawn(paymentFieldTokenSagas),
    spawn(paymentMethodsSagas),
    spawn(schedulePaymentSagas),
    spawn(paymentTransactionsSaga),
    spawn(shoppingListsSagas),
    spawn(quickShopSaga),
    spawn(quotesSagas),
    spawn(itemsReplacementsSagas),
    spawn(orderCutOffSagas),
    spawn(customerNotificationSagas),
    spawn(similarItemsSagas),
    spawn(youMayAlsoNeedItemsSaga),
    spawn(moreProductsFromBrand),
    spawn(guestSagas),
    spawn(captchaSagas),
    spawn(paymentHistorySaga),
    spawn(paymentDetailsSaga),
    spawn(autopaySagas),
  ]);
}
