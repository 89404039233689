import { FC } from 'react';
import { Modal } from 'components/modals/common/modal';
import { GuideSection } from 'pages/account/components/guide-section';

import './how-to-create-shopping-list-modal.scss';

export interface IHowToCreateShoppingListModalProps {
  isOpen: boolean;
  onClose: () => void;
  contentStackPath: string;
}

export const HowToCreateShoppingListModal: FC<IHowToCreateShoppingListModalProps> = ({
  isOpen,
  onClose,
  contentStackPath,
}) => (
  <Modal className="how-to-create-list-modal" isOpen={isOpen} onClose={onClose} size="medium_large" withBackdropClick>
    <GuideSection contentStackPath={contentStackPath} isModal />
  </Modal>
);
