import { put, takeLatest, take } from 'redux-saga/effects';
import { Actions } from 'constants/actions.enum';
import { IAction } from 'types/actions';
import { cancelScheduledPayment, cancelScheduledPaymentActionConstants } from 'store/schedule-payment/actions';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';

export interface ICancelScheduledPaymentPayload {
  transactionId: string;
  onSuccessCallBack?: () => void;
  onFailCallBack?: () => void;
}

export function* cancelScheduledPaymentSuccessSagaHandler({ payload }: IAction<ICancelScheduledPaymentPayload>) {
  yield take(cancelScheduledPaymentActionConstants[Actions.SUCCESS]);
  if (payload.onSuccessCallBack) {
    payload.onSuccessCallBack();
  }
}

export function* cancelScheduledPaymentFailSagaHandler({ payload }: IAction<ICancelScheduledPaymentPayload>) {
  yield take(cancelScheduledPaymentActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack) {
    payload.onFailCallBack();
  }
}

export function* cancelScheduledPaymentHandler({
  payload: { transactionId },
}: IAction<ICancelScheduledPaymentPayload>) {
  const url = `${Endpoints.PAYMENT_HISTORY}/${transactionId}`;
  yield put(doRequest(cancelScheduledPayment, url, 'delete'));
}

export default function* cancelScheduledPaymentSaga() {
  yield takeLatest(cancelScheduledPaymentActionConstants[Actions.REQUEST], cancelScheduledPaymentSuccessSagaHandler);
  yield takeLatest(cancelScheduledPaymentActionConstants[Actions.REQUEST], cancelScheduledPaymentFailSagaHandler);
  yield takeLatest(cancelScheduledPaymentActionConstants[Actions.REQUEST], cancelScheduledPaymentHandler);
}
