import { FC } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import iconBreadcrumb from 'assets/images/icons/icon-breadcrumb.svg';
import { useLocalStorage } from 'hooks/use-local-storage';
import { CorContentStackLanguageURLMap } from 'constants/cor-locale.enum';
import { Routes } from 'constants/routes.enum';
import { isInternationalSite } from 'utils/get-locale-params';

import './breadcrumbs.scss';

export interface IBreadcrumbProps {
  name: string;
  url?: string;
  onClick?: () => void;
}

export interface IBreadcrumbsProps {
  breadcrumbs: IBreadcrumbProps[];
  className?: string;
  altSeparatorIcon?: string;
}

export const Breadcrumbs: FC<IBreadcrumbsProps> = ({ breadcrumbs, className, altSeparatorIcon, ...restProps }) => {
  const newBreadcrumbs = [...breadcrumbs];
  const currentPage = newBreadcrumbs.pop();
  const separatorIcon = altSeparatorIcon ? altSeparatorIcon : iconBreadcrumb;
  const [locale] = useLocalStorage('locale', null);
  const localeUrl = CorContentStackLanguageURLMap.get(locale);

  return (
    <nav className={classNames('breadcrumbs', className)} {...restProps}>
      {newBreadcrumbs.map((breadcrumb) => (
        <div className="breadcrumbs__item" key={breadcrumb.name}>
          {breadcrumb.url ? (
            <Link
              to={
                isInternationalSite(localeUrl)
                  ? `${localeUrl}${breadcrumb.url === Routes.HomePage ? '' : breadcrumb.url}`
                  : breadcrumb.url
              }
            >
              {breadcrumb.name}
            </Link>
          ) : (
            <span onClick={breadcrumb.onClick}>{breadcrumb.name}</span>
          )}
          <span className="breadcrumbs__separator">
            <img src={separatorIcon} alt="breadcrumb divider icon" />
          </span>
        </div>
      ))}
      {currentPage && <h1 className="breadcrumbs__item breadcrumbs__item--active">{currentPage.name}</h1>}
    </nav>
  );
};
