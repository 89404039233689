import { FC, RefObject } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import { useContent } from 'hooks/use-content';
import { IModalProps, Modal } from 'components/modals/common/modal';
import { useBreakpoint } from 'hooks/use-breakpoint';
import { selectIsImpersonateModeOn, selectIsInternalUser } from 'store/auth/selectors';
import { selectIsHeaderCollapsed } from 'store/ui/selectors';
import { ICustomerNotificationItem } from 'types/notifications';
import { ExpandableSection } from 'components/expandable-section';
import iconBreadcrumbs from 'assets/images/icons/icon-breadcrumb.svg';
import { IconWithHover } from 'components/icon-with-hover';
import { CorButton } from 'components/cor-button';
import { MobilePanelCloser } from 'components/layout/cor-header/components';
import { useEnvironment } from 'hooks/use-environment';
import { ModalHeader } from 'components/modals/common/modal/components';

import './notifications-list-modal.scss';

export interface INotificationsListModalProps extends IModalProps {
  notifications: any;
  excludeFromOutsideClickContentRef?: RefObject<any>;
  isMobileHeaderCollapsed?: boolean;
  notificationItemUrl: (url: string) => void;
}

export const NotificationsListModal: FC<INotificationsListModalProps> = ({
  isOpen = false,
  onClose = () => {},
  notifications,
  excludeFromOutsideClickContentRef,
  isMobileHeaderCollapsed,
  notificationItemUrl,
}) => {
  const contentStackPath = 'modals.0.notification_list_modal.0';
  const { isMobile, isDesktop } = useBreakpoint();
  const { getContentByKey } = useContent();
  const { isProduction } = useEnvironment();
  const isInternal = useSelector(selectIsInternalUser);
  const isHeaderCollapsed = useSelector(selectIsHeaderCollapsed);
  const isImpersonateModeOn = useSelector(selectIsImpersonateModeOn);

  const handleNotificationItemLinkClick = (url) => {
    notificationItemUrl(url);
    onClose();
  };

  const renderCustomerNotifications = (notifications: ICustomerNotificationItem[]) =>
    Object.keys(notifications).length > 0 &&
    Object.values(notifications).map((notificationsInner) => {
      return Object.values(notificationsInner).map((notification, key) => (
        <li className="notifications-list-modal__content__notification" key={key}>
          <ExpandableSection
            title={`${notification.title} (${notification.count})`}
            className="notification__expandable-section"
            forceIsExpanded
            ExpandableIcon={
              <IconWithHover
                icon={iconBreadcrumbs}
                hoverIcon={iconBreadcrumbs}
                alt="Expandable Icon"
                className="expandable-section__custom-expand-icon"
              />
            }
          >
            <ul>
              {!isEmpty(notification.items) &&
                notification.items.map((item, key) => {
                  return (
                    <li className="notification-message" key={key}>
                      <Link to={item.url} onClick={() => handleNotificationItemLinkClick(item.url)}>
                        {item.message}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </ExpandableSection>
        </li>
      ));
    });

  const parentClassNames = classNames('notifications-list-modal__wrapper', {
    'internal-collapsed-mobile-header': isMobile && isInternal && isMobileHeaderCollapsed,
    'collapsed-mobile-header': isMobile && !isInternal && isMobileHeaderCollapsed,
    'internal-full-mobile-header': isMobile && isInternal && !isMobileHeaderCollapsed,
    'environment-mobile-header': isMobile && !isInternal && !isMobileHeaderCollapsed,
    'full-mobile-header': isMobile && isProduction,
    'authorized-desktop-header-sticky': isDesktop && isHeaderCollapsed,
    'authorized-desktop-header-sticky-impersonation': isDesktop && isHeaderCollapsed && isInternal,
    'authorized-desktop-header-impersonation': isDesktop && isImpersonateModeOn,
  });

  return (
    <Modal
      className="notifications-list-modal"
      parentClassName={parentClassNames}
      withBackdropClick
      isOpen={isOpen}
      excludeFromOutsideClickContentRef={excludeFromOutsideClickContentRef}
      onCloseAlt={onClose}
    >
      {isMobile ? (
        <MobilePanelCloser label={getContentByKey(`${contentStackPath}.header_title`, '')} handleMenuOpen={onClose} />
      ) : (
        <ModalHeader titleContentstackPath={`${contentStackPath}.header_title`} />
      )}

      <div className="notifications-list-modal__content-wrapper">
        <ul className="notifications-list-modal__content">{renderCustomerNotifications(notifications)}</ul>
      </div>
      {isMobile && (
        <div className="notifications-list-modal__button-wrapper">
          <CorButton className="notifications-list-modal__button-wrapper--button" color="primary" onClick={onClose}>
            {getContentByKey(`${contentStackPath}.back_button`, '')}
          </CorButton>
        </div>
      )}
    </Modal>
  );
};
