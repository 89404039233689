import { get } from 'lodash-es';
import React from 'react';
import { corContentstackAssetLinkResolver } from 'corporate-utils/cor-contentstack-asset-link-resolver';
import { CorButtonLink } from 'components/cor-button-link';
import { useContentstackContent } from 'hooks/use-contentstack-content';

const CorContentstackButton = ({ contentKey = '', className = '', ...props }) => {
  const content = useContentstackContent();
  const button = get(content, contentKey, {});
  const asset = corContentstackAssetLinkResolver(button)?.url;

  return (
    <CorButtonLink
      className={className}
      color={(button?.button_style || 'primary').toLowerCase()}
      href={asset ?? button?.button?.href}
      target={button?.open_in_a_new_tab ? '_blank' : '_self'}
      iconUrl={get(button?.button_icon, '[0].cdn_url')}
      iconPosition={button?.button_alignment}
      asset={asset}
      {...props}
    >
      {button?.button?.title}
    </CorButtonLink>
  );
};

export default React.memo(CorContentstackButton);
