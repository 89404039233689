export enum Endpoints {
  CONTENTSTACK_URLS = '/contentstack/search/urls',
  CONTENTSTACK_TAGS = '/contentstack/search/tags',
  CONTENTSTACK_QUERY = '/contentstack/search/query',
  LOGIN = '/customers/login',
  CUSTOMER_LOCATIONS = '/customers/locations',
  PASSWORDS = '/passwords',
  ACCOUNT_INFORMATION = '/customers/accounts/information',
  UPDATE_ACCOUNT_PERSONAL_INFO = '/customers/accounts/sales',
  REGISTER_ADDITIONAL_USER = '/customers/createprofile-for-existing-account',
  VALIDATE_USER_REGISTRATION_TOKEN = '/customers',
  ACCOUNT_PASSWORD = '/customers/accounts/passwords',
  CUSTOMERS = '/customers',
  CUSTOMER_EMAIL_VALIDATION_BY_TOKEN = '/customers/email',
  ACCOUNT_RETRIEVAL = '/customers/validation',
  VALIDATION_WITH_CUSTOMER_NUMBER_AND_ZIP_CODE = '/customers/validation-with-customer-number-and-zip-code',
  CREATE_PROFILE = '/customers/createprofile',
  CUSTOMER_INFO = '/customers/info',
  SEARCH_KEY = '/search/key',
  USERS = '/users',
  INVITE_USER = '/users/invite',
  REINVITE_USER = '/users/reinvite',
  UPDATE_USER_PERSONAL_INFO = '/users/personal-information',
  GET_PRODUCTS = '/catalog/search',
  CATALOG_SKUS = '/catalog/skus',
  SET_CART_MESSAGE_VIEWED = '/carts/viewed/notifications',
  CART = '/carts',
  CART_REMOVE_ALL = '/carts/all',
  CART_ADD_MULTIPLE = '/carts/line-items',
  CART_DISCOUNTS = '/carts/discount-codes',
  SALES_REP_INFORMATION = '/customers/accounts/meta/information',
  CART_CHECKOUT = '/checkout',
  CATALOG_CATEGORIES_TREE = '/catalog/categories/tree',
  ORDER_HISTORY = '/orders',
  ORDER_HISTORY_PRODUCTS = '/product-list-view',
  CAROUSEL_PRODUCTS = '/catalog/carousel',
  SUBMIT_ORDER = '/order',
  SEARCH_PRODUCTS = '/catalog/search',
  SEARCH_SUGGESTIONS = '/catalog/suggestions',
  CONTACT_MSR = '/customers/contact/msr',
  DASHBOARD_EMPLOYEE = '/dashboard/employee',
  DASHBOARD_MSR_LOCATIONS_BY_NAME = '/dashboard/employee/locations/by-name',
  DASHBOARD_MSR_LOCATIONS_BY_RECENT_ORDERS = '/dashboard/employee/locations/by-recent-orders',
  DASHBOARD_MSR_LOCATIONS_BY_CUSTOMER_SEARCH = '/dashboard/employee/locations/search',
  DASHBOARD_MSR_LOCATIONS_BY_DAY_OF_WEEK = '/dashboard/employee/locations/by-day-of-week',
  DASHBOARD_CSR_LOCATIONS = '/dashboard/csr/locations/search',
  DASHBOARD_MSM_LOCATIONS = '/dashboard/msm/msr/locations',
  DASHBOARD_MSM_MSR_LOCATIONS_CART_STATUS_ORDERS = '/dashboard/msm/msr/locations/cart-status-and-orders',
  DASHBOARD_MSM_MSR_SEARCH = '/dashboard/msm/msr',
  DASHBOARD_CSR_CUSTOMERS = '/dashboard/csr/customers',
  DASHBOARD_QUOTES = '/dashboard/employee/quotes',
  LOCATIONS_BY_MSR_ID = '/dashboard/csr/customers/by-msr-id/',
  DASHBOARD_CSR = '/dashboard/csr',
  IMPERSONATION_START = '/impersonation/start',
  IMPERSONATION_STOP = '/impersonation/stop',
  LOGOUT = '/customers/logout',
  OPT_OUT = '/customers/opt-out',
  PRODUCT_DETAILS = '/catalog',
  SKU_DETAILS = '/catalog',
  SKU_SUBTOTAL_PRICE = '/price',
  SHOPPING_LISTS = '/shopping-lists',
  SHOPPING_LISTS_CONVERT_MARKETING_TO_CUSTOM = '/shopping-lists/convert-marketing-to-custom',
  IMPORT_SHOPPING_LIST = '/shopping-lists/import',
  FAVORITES_LIST_ITEMS = '/shopping-lists/favorites/items',
  RECENTLY_VIEWED_ITEMS = '/customers/recently-viewed-items',
  QUOTES = '/quotes',
  ANALYTICS_PRELOAD = '/analytics/preload',
  UPDATE_PASSWORD_FROM_TEMPORARY = '/passwords/reset',
  GENERATE_TEMPORARY_PASSWORD = '/passwords/temp',
  CUT_OFF_NOTIFICATION = '/cut-off-notification',
  CUT_OFF_NOTIFICATION_VIEWED = '/carts/viewed/cutoff-notification-closed-date',
  CUT_OFF_EXPIRED_NOTIFICATION_VIEWED = '/carts/viewed/cutoff-failed-notification',
  SSO_URL = '/saml2/authenticate/dawnfoods',
  NOTIFICATIONS = '/customers/notification',
  ALL_SKUS_PRODUCT_LIST = '/all-skus-product-list',
  DASHBOARD_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_NAME = '/assigned-to-csr/accounts/dashboard/locations/by-name',
  DASHBOARD_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_RECENT_ORDERS = '/assigned-to-csr/accounts/dashboard/locations/by-recent-orders',
  DASHBOARD_LOCATIONS_WITH_ASSIGNED_ACCOUNTS_BY_DAY_OF_WEEK = '/assigned-to-csr/accounts/dashboard/locations/by-day-of-week',
  PRODUCT_RECOMMENDATIONS = '/product-recommendations-before-submission',
  BUY_IT_AGAIN = '/buy-it-again',
  CAPTCHA_SITE_KEY = '/recaptcha/site-key',
  AVAILABLE_CATALOG_SKUS = '/catalog/available/skus',
  PRODUCT_SOLUTION_CAROUSEL_PRODUCTS = '/global/store/catalog/product-solution',
  MORE_FROM_BRAND = '/catalog/more-from-brand',
  GET_PAYMENT_FIELD_TOKEN = '/payment/methods/payment-fields-tokens',
  GET_PAYMENT_METHODS = '/payment/methods',
  ADD_CREDIT_CARD = '/payment/methods/cc',
  DELETE_CREDIT_CARD = '/payment/methods/cc',
  BANK_ACCOUNTS = '/payment/methods/ach',
  BANK_ACCOUNTS_EFT_PAD = '/payment/methods/pad',
  INVOICES = '/payment/invoices',
  INVOICES_SUMMARY = '/payment/invoices/summary',
  SUBMIT_CREDIT_CARD_PAYMENT = '/payment/transactions/cc',
  SUBMIT_ACH_PAYMENT = '/payment/transactions/ach',
  SUBMIT_PAD_PAYMENT = '/payment/transactions/pad',
  PAYMENT_HISTORY = '/payment/transactions',
  INVOICES_TOTAL_AMOUNT = '/payment/invoices/dashboard/summary',
  GET_INVOICE_PDF = '/payment/invoices/pdf',
  AUTOPAY = 'payment/methods/autopay',
}
