import { createReducer } from 'store/reducer-creator';
import { Actions } from 'constants/actions.enum';
import { PaymentMethods } from 'constants/payment-methods.enum';
import { PAGINATION_SETTINGS } from 'constants/pagination.enum';
import * as actions from './actions';
import types from './action-types';

export interface ITransaction {
  id: string;
  ctId: string;
  date: string;
  amount: number;
  currency: string;
  paymentMethod: keyof typeof PaymentMethods;
  paymentProvider?: string;
  accountType?: any;
  accountNumber: string;
  status: 'SUCCESSFUL' | 'PROCESSING' | 'SCHEDULED' | 'DECLINED';
  paidByAutoPay?: boolean;
}

export interface IPaymentHistory {
  paymentTransactions: ITransaction[];
  total: number;
  offset: number;
  limit: number;
}

export const paymentHistoryInitialState: IPaymentHistory = {
  paymentTransactions: [],
  total: 0,
  offset: PAGINATION_SETTINGS.defaultOffset,
  limit: PAGINATION_SETTINGS.defaultLimit,
};

const paymentHistoryReducer = createReducer<IPaymentHistory>(
  {
    [actions.getPaymentHistoryActionConstants[Actions.SUCCESS]]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [types.PAYMENT_HISTORY_PAGINATION_CHANGE]: (state, { payload }) => ({
      ...state,
      offset: state.limit * payload.current,
    }),
  },
  paymentHistoryInitialState
);

export default paymentHistoryReducer;
