import { put, takeLatest, take } from 'redux-saga/effects';
import { Endpoints } from 'constants/endpoints.enum';
import { doRequest } from 'store/api/actions';
import { getPaymentMethods, getPaymentMethodsActionConstants } from 'store/payment-methods/actions';
import { Actions } from 'constants/actions.enum';
import { IAction } from 'types/actions';

export interface IGetPaymentMethodsPayload {
  onFailCallBack?: (status: number, messageId: string) => void;
}
export function* getPaymentMethodsHandler() {
  const url = Endpoints.GET_PAYMENT_METHODS;
  yield put(doRequest(getPaymentMethods, url, 'get'));
}

export function* getPaymentMethodsOnFailSagaHandler({ payload }: IAction<IGetPaymentMethodsPayload>) {
  const { error } = yield take(getPaymentMethodsActionConstants[Actions.FAIL]);
  if (payload.onFailCallBack && error) {
    payload.onFailCallBack(error.status, error.errorMessageId);
  }
}

export default function* getPaymentMethodsSaga() {
  yield takeLatest(getPaymentMethodsActionConstants[Actions.REQUEST], getPaymentMethodsHandler);
  yield takeLatest(getPaymentMethodsActionConstants[Actions.REQUEST], getPaymentMethodsOnFailSagaHandler);
}
